<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        v-for="notification in toasts"
        :key="notification.id"
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <NotificationBase
          :notification="notification"
          @close="removeNotification(notification.id)"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";

const notificationStore = useNotificationStore();
const { toasts } = storeToRefs(notificationStore);

const removeNotification = (id: string) => {
  notificationStore.removeToast(id);
};
</script>

<template>
  <div
    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <CheckCircleIcon
            v-if="props.notification.type === 'success'"
            class="h-6 w-6 text-green-400"
            aria-hidden="true"
          />
          <ExclamationTriangleIcon
            v-if="props.notification.type === 'warning'"
            class="h-6 w-6 text-yellow-400"
            aria-hidden="true"
          />
          <XCircleIcon
            v-if="props.notification.type === 'error'"
            class="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
          <InformationCircleIcon
            v-if="props.notification.type === 'info'"
            class="h-6 w-6 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p
            class="text-sm font-medium text-gray-900"
            data-cy="notification-title"
          >
            {{ props.notification.message }}
          </p>
        </div>
        <div class="ml-4 flex flex-shrink-0">
          <button
            type="button"
            class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            @click="onClose"
          >
            <span class="sr-only">Close</span>
            <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import { TimeoutableToast } from "~/types/shared";

const props = defineProps({
  notification: {
    type: Object as PropType<TimeoutableToast>,
    required: true,
  },
});

const timeout = 5000;

const emit = defineEmits(["close"]);

const onClose = () => {
  emit("close");
};

onMounted(() => {
  setTimeout(onClose, timeout);
});
</script>
